define('ember-cli-material-design-icons/components/material-design-icon', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'i',
    name: '',
    classNameBindings: ['prefixedClass'],

    prefixedClass: _ember['default'].computed('name', function () {
      return 'mdi mdi-' + this.get('name');
    })
  });
});