define('ember-screen/breakpoint', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports['default'] = breakpoint;

  var computed = _ember['default'].computed;

  function breakpoint(mediaQuery) {
    return computed('width', function () {
      return this.win.matchesMediaQuery(mediaQuery);
    });
  }
});